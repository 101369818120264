import React, {useCallback, useEffect, useState} from "react";
import Helmet from "react-helmet";
import {graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";
import countries from "../../data/countries";
import {getCountry, setCountry, getUsersCountry} from "../utils/location";
import Loader from "../components/Loader";
import {PageProvider} from './../Context/pageContext';
import CookieBanner from "../components/CookieBanner";

const PageTemplate = ({
                          location,
                          data: {
                              mdx: {body, slug},
                          },
                          pageContext
                      }) => {

    if (typeof window !== "undefined") {
        window.reactElementCounter = 0;
        window.titleCounter = 0;
    }

    const [loading, setLoading] = useState(true);
    let country = getCountry();

    const utmData = () => {
        const utmDataFromStorage = JSON.parse(window.localStorage.getItem("utmData"));

        // if no existing utm data, or existing utm data is default direct source, then set utm
        if (window && window.localStorage && (!utmDataFromStorage || utmDataFromStorage.utm_source === "Direct")) {
            const defaultUtm = 'utm_campaign=supernova&utm_content=Trial&utm_medium=Organic&utm_source=Direct&utm_term=trial&utm_url=covidtrialandyou.com'
            const search = window.location.search;

            const params = new URLSearchParams(search);
            const utms = new URLSearchParams(defaultUtm);
            const paramObj = {};

            // get all default values first
            Array.from(utms.keys()).forEach((value) => {
                paramObj[value] = utms.get(value);
            });

            // check search query and overwrite any default values
            Array.from(params.keys()).forEach((value) => {
                if (value.startsWith('utm_')) {
                    paramObj[value] = params.get(value);
                }
            });

            if (typeof window !== 'undefined') {
                window.addEventListener('CookiebotOnConsentReady', () => {
                    if (window.CookieConsent && window.CookieConsent.consent) {
                        const consent = window.CookieConsent.consent;
                        if (consent.marketing === true) {
                            window.localStorage.setItem('utmData', JSON.stringify(paramObj));
                        }
                    }
                }, false);
            }

            
        }
    }

    const countryLookup = useCallback(async () => {
        // no country saved in local storage, do IP lookup
        if (!country) {
            setLoading(true);
            const userCountry = await getUsersCountry();

            if (userCountry.country_code && userCountry.language_code) {
                const newCountry = userCountry.language_code.substring(0, 2) + '-' + userCountry.country_code;

                // check code exists in list of supported countries before saving to local storage
                countries.forEach(country => {
                    if (country.url === newCountry) {
                        setCountry(newCountry);
                    }
                });
            }

            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [country])

    useEffect(() => {
        utmData();
        countryLookup()

        
    }, [countryLookup]);

    const Head = () => {

        return (
            <>
                <Helmet title={pageContext.title} defer={false}/>
                <Helmet>
                    <meta property="og:image" content={pageContext.language ? `https://covidtrialandyou.com/static-images/${pageContext.language}/og-image.webp` : `https://covidtrialandyou.com/static-images/og-image.webp`}/>
                    <meta property="og:title" content={pageContext.title}/>
                    <meta property="og:description" content={pageContext.metaDescription}/>
                    <meta property="og:url" content={`https://covidtrialandyou.com${pageContext.currentPage}`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image:width" content="1200"/>
                    <meta property="og:image:height" content="630"/>

                    <meta name="twitter:image" content={pageContext.language ? `https://covidtrialandyou.com/static-images/${pageContext.language}/og-image.webp` : `https://covidtrialandyou.com/static-images/og-image.webp`}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:domain" content="covidtrialandyou.com"/>
                    <meta property="twitter:url" content={`https://covidtrialandyou.com${pageContext.currentPage}`}/>
                    <meta name="twitter:title" content={pageContext.title}/>
                    <meta name="twitter:description" content={pageContext.metaDescription}/>
                   

                    <html lang={pageContext.language}/>
                    <meta name="description" content={pageContext.metaDescription}/>
                    <meta charSet="utf-8"/>
                    <meta name="facebook-domain-verification" content="6xgqtyvk4qdn3j64yrf0bse6dm2dkj" />

                    
                </Helmet>
            </>
        )
    };

    const is404 = slug === '404' ? true : false;
    let isBot = false;

    if (typeof window !== 'undefined' && /bot|googlebot|crawler|spider|robot|crawling/i.test(window.navigator.userAgent)) {
        isBot = true;
    }

    if (loading) {
        return (
            <>
                <Head/>
                <Loader/>
            </>
        )
    } else {

        // console.log('------LOADING------');
        // console.log('window',window.location);

        if (location.pathname !== '/') {
            const tempLocation = location.pathname.split('/');
            country = tempLocation[1];
            let resetCountry = true;
            countries.forEach(validCountry => {
                if (validCountry.url === country) {
                    setCountry(country);
                    resetCountry = false;
                }
            });

            if (resetCountry === true) {
                country = null
            }
        }


        return (
           
            <PageProvider value={pageContext}>
                <Head/>
                <CookieBanner/>
                <div>
                    {country || is404 || isBot || location.pathname === '/' ? (
                        <>
                            <a href="#main" className="skip-link">Skip to content</a>
                            <MDXRenderer>{body}</MDXRenderer>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
            </PageProvider>
           
        );

    }
};

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      slug
    }
  }
`;


export default PageTemplate;
