/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import CookieIDs from '../../../data/countries';

const CookieBanner = () => {
    const currentCode = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : '';
    const currentCountry = CookieIDs.filter((country) => {
        return country.url === currentCode;
    });

    function loadGoogleAnalytics() {
       
        // Google Tag Manager script
        (function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W98B4QK');    

        window.dataLayer = window.dataLayer || [];

        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-W98B4QK';
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';

        noscript.appendChild(iframe);
        const body = document.body;
        body.insertBefore(noscript, body.firstChild);
    }

    function loadHotJar() {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:process.env.GATSBY_HOTJAR_ID,hjsv:process.env.GATSBY_HOTJAR_SV};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');    
    }
    

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('CookiebotOnAccept', () => {
                if (window.CookieConsent && window.CookieConsent.consent) {
                    const consent = window.CookieConsent.consent;
                    if (consent.statistics === true && consent.marketing === true) {
                        loadGoogleAnalytics();
                    }
                    if (consent.statistics === true) {
                        loadHotJar();
                    }
                }
            }, false);
        }
    }, []);

    if (!currentCode) {
        return null;
    }

    return (
        <>
            <Helmet>
                <script async id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid={currentCountry && currentCountry.length > 0 ? currentCountry[0].cookieID : '43d8dcdb-b623-4ed3-b5e2-7c0d67c37eb3'} data-blockingmode="auto" />
            </Helmet>
        </>
    );
};

export default CookieBanner;
